if (document.querySelector(".form-styling")) {
    const inputTimes = document.querySelectorAll("input[type='date']");

    inputTimes.forEach((item) => {
        let date = new Date().toISOString().split("T")[0];
        item.getAttribute("max");
        item.setAttribute("max", date);

        item.addEventListener("change", (e) => {
            let inputEl = item.closest(".input");
            if (inputEl.querySelector("div")) {
                inputEl.querySelector("div").remove();
            }
            let div = document.createElement("div");
            inputEl.appendChild(div);
            let tagDiv = inputEl.querySelector("div");
            tagDiv.innerHTML = `${new Date(e.target.value).toLocaleDateString()}`;
        });
    });

    //input datei
    const inputDateis = document.querySelectorAll("input[type='file']");
    inputDateis.forEach((item) => {
        item.addEventListener("change", (e) => {
            let inputEl = item.closest(".input");
            if (inputEl.querySelector("span")) {
                inputEl.querySelector("span").remove();
            }
            let span = document.createElement("span");
            inputEl.appendChild(span);
            let spanTag = inputEl.querySelector("span");
            let files = Array.from(e.target.files);
            spanTag.innerHTML = `
                ${files[0].name}
                <button type="button">&#9587;</button>
            `;
            if (inputEl.querySelector("span button")) {
                let buttonClose = inputEl.querySelector("span button");
                buttonClose.addEventListener("click", () => {
                    item.value = null;
                    inputEl.querySelector("span").remove();
                });
            }
        });
    });
}
