let Switches = document.querySelectorAll(".tab__switch--title");
// let activeTab = document.querySelector(".tab__items.tab__items--active")

Switches.forEach((title) => {
    title.addEventListener("click", function () {
        Switches.forEach((item) => {
            item.classList.remove("switch--active");
        });
        this.classList.add("switch--active");

        let value = this.getAttribute("data-value");
        let Tabs = document.querySelectorAll(".tab__items");

        Tabs.forEach((tab) => {
            let tabValue = tab.getAttribute("data-value");

            if (tabValue == value) {
                tab.classList.add("tab__items--active");
            } else {
                tab.classList.remove("tab__items--active");
            }
        });
    });
});
