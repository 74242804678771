let produktVideos = document.querySelectorAll(".produktvideos");
produktVideos.forEach((item) => {
    let produktVideosItems = item.querySelectorAll(".produktvideos__card");

    if (produktVideosItems.length > 4) {
        item.querySelector(".produktvideos__btn").style.display = "block";
        item.querySelector(".produktvideos__btn").addEventListener("click", () => {
            produktVideosItems.forEach((card) => {
                card.style.display = "block";
            });
            item.querySelector(".produktvideos__btn").style.display = "none";
        });
    }
});
