const timeline = document.querySelector(".timeline");

if (timeline) {
    timelineLoadMore();

    function timelineLoadMore() {
        const loadMoreButton = document.querySelector(".timeline__loadmore");

        loadMoreButton?.addEventListener("click", function () {
            const ActiveItems = document.querySelectorAll(".timeline__item");
            let oldItems = 0;

            ActiveItems.forEach((activeItem) => {
                if (!activeItem.classList.contains("timeline__item--hidden")) {
                    oldItems += 1;
                }
            });

            let addedItems = 5;
            let newItems = oldItems + addedItems;

            for (let i = 0; i < newItems; i++) {
                ActiveItems[i]?.classList.remove("timeline__item--hidden");

                if (!ActiveItems[i]) {
                    loadMoreButton.classList.add("is__hidden");
                }
            }
        });
    }
}
