import { Location } from "./Location";
import { Point } from "./Point";
import React from "react";

export function SearchBoxItem(props: {
    location: Location;
    setCenter: (value: Point) => void;
    setZoom: (zoom: number) => void;
}): React.ReactNode {
    const { location, setCenter, setZoom } = props;

    function handleClick(e: React.MouseEvent<HTMLLIElement>) {
        const searchCard = e.target.closest(".search-card");
        if (!searchCard) return;

        const cardTitle = searchCard.querySelector(".search-card__title")?.innerHTML;
        const cardAddress = searchCard.querySelector(".search-card__address")?.innerHTML;
        const cardIdentifier = cardTitle + cardAddress;

        // Make sure to close all windows
        document.querySelectorAll(".info-window").forEach((window) => {
            window.classList.remove("highlight");
        });

        // Center the map and zoom in
        setCenter({ lat: location.latitude, lng: location.longitude });
        setZoom(15);

        // Highlight the corresponding info window
        setTimeout(() => {
            document.querySelectorAll(".info-window").forEach((window) => {
                const windowTitle = window.querySelector(".name")?.innerHTML;
                const windowAddress = window.querySelector(".address")?.innerHTML;
                const windowIdentifier = windowTitle + windowAddress;

                window.classList.toggle("highlight", cardIdentifier === windowIdentifier);
            });
        }, 300); // Adjust the timeout delay as needed

        // Scroll card wrapper to the top
        const cardWrapper = document.querySelector(".search-banner");
        if (cardWrapper) {
            cardWrapper.scrollTo({ top: 0, behavior: "smooth" });
        }

        // Highlight the clicked search card
        document.querySelectorAll(".search-card").forEach((card) => {
            card.classList.remove("highlight");
        });
        searchCard.classList.add("highlight");
    }

    let phone = location.phone;

    return (
        <li className="search-card" onClick={handleClick}>
            <h4 className="search-card__title">{location.name}</h4>
            <span className="search-card__address">{location.address}</span>
            <div className="search-card__adresse">
                <span>{location.postcode}</span>
                <div className="search-card__city">{location.city}</div>
            </div>
            <a href={`tel:${phone.replaceAll(" ", "")}`} className="search-card__phone">
                {location.phone}
            </a>
            <a href={`mailto:${location.email}`} className="search-card__email">
                {location.email}
            </a>
            {location.website1 && (
                // @ts-ignore
                <a className="search-card__link" href={location.website1} target={"_blank"}>
                    {window.TYPO3.lang.website}
                </a>
            )}
            {/* <button type="button" className="search-card__btn">Warenkorb anfragen</button> */}
        </li>
    );
}
