// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

textimageSlider();

// init Swiper:
function textimageSlider() {
    const swiper = new Swiper(".slider", {
        // Optional parameters
        modules: [Navigation, Pagination],
        direction: "horizontal",
        loop: true,
        slidesPerView: 1,

        // Navigation arrows
        navigation: {
            nextEl: ".slider__arrow--next",
            prevEl: ".slider__arrow--prev",
        },
        pagination: {
            el: ".slider__pagination",
        },
    });
}
