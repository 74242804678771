export const AJAX_LIST = {
    SMEDIAFAIRCALENDAR_AJAX_LOADMOREBUTTON: 1715609809,
    SMEDIAPIM_AJAX_USAGEINSPIRATIONLOADMORE: 1815609809,
    SMEDIAPIM_AJAX_USAGEINSPIRATIONFILTER: 1915609809,
    SMEDIAPIM_AJAX_CUSTOMERREPORTLOADMORE: 2015609809,
    SMEDIAPIM_AJAX_CUSTOMERREPORTFILTER: 2115609809,
};

export function ajaxFetch(typeNum: number, requestInit: RequestInit): Promise<Response> {
    const defaultInit = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    };

    const mergedInit = Object.assign({}, defaultInit, requestInit);

    // Function to transform a JSON object into a query string
    function jsonToQueryString(json: any) {
        return Object.keys(json)
            .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(json[key]))
            .join("&");
    }

    const url = window.location.href;
    // Check if the method is POST and there is a body
    if (mergedInit.method.toUpperCase() === "POST" && mergedInit.body) {
        // Parse the JSON body to an object
        const bodyObject = JSON.parse(mergedInit.body as string);

        // Create a query string from the body
        const queryString = jsonToQueryString(bodyObject);

        // Modify the request to be a GET request
        mergedInit.method = "GET";
        delete mergedInit.body; // Remove the body as it's not needed for GET requests

        // Append the query string to the URL
        return fetch(`${url}/?type=${typeNum}&${queryString}`, mergedInit);
    }

    return fetch(`${url}/?type=${typeNum}`, mergedInit);
}
