footerAccordion();

function footerAccordion() {
    const Items = document.querySelectorAll(".mainmenu__link--container");

    Items.forEach((item) => {
        item.addEventListener("click", function () {
            const subpage = item.querySelector(".mainmenu__subpages");
            const arrow = item.querySelector(".mainmenu__icon--chevron");

            if (subpage.classList.contains("is__visible")) {
                subpage.classList.remove("is__visible");
                arrow.classList.remove("is__rotating");
            } else {
                subpage.classList.add("is__visible");
                arrow.classList.add("is__rotating");
            }
        });
    });
}
