import { ajaxFetch } from "./Ajax";

export interface SecondFilters {
    countryFilters?: any[];
    applicationFilters?: any[];
}

export async function pagination(ajaxNum: number, e: any, firstFilters: Array<any>, secondFilters: SecondFilters) {
    const button = e.currentTarget as HTMLButtonElement;

    let { page } = button.dataset as any;
    page = parseInt(page) + 1;

    let node = document.querySelector("script#SMEDIAPIM_LIST_HAS_FURTHER_RECORDS") as HTMLScriptElement;
    if (node !== null) {
        node.parentNode?.removeChild(node);
    }

    const response = await ajaxFetch(ajaxNum, {
        body: JSON.stringify({
            page: page,
            firstFilters: firstFilters,
            ...secondFilters,
        }),
    });

    const text = await response.text();
    const buttonWrapper = document.querySelector("#button-wrapper") as HTMLDivElement;

    buttonWrapper.insertAdjacentHTML("beforebegin", text);

    node = document.querySelector("script#SMEDIAPIM_LIST_HAS_FURTHER_RECORDS") as HTMLScriptElement;
    eval(node.textContent as string);
    if (window["SMEDIAPIM_LIST_HAS_FURTHER_RECORDS"] === 0) {
        buttonWrapper.removeChild(button);
    }

    button.setAttribute("data-page", page.toString());
}
