import { AJAX_LIST } from "../../Helper/Ajax";
import { getApplicationFilter, getCountryFilter, getProductFilter } from "../../Helper/InspirationFilter";
import { pagination } from "../../Helper/InspirationPagination";

(() => {
    const loadMoreButton = document.querySelector("#inspirationButton") as HTMLButtonElement | null;

    if (loadMoreButton === null) {
        return;
    }

    // Select all Country, and Application checkboxes
    const applicationCheckboxes = document.querySelectorAll(".ApplicationFilter");
    const countryCheckboxes = document.querySelectorAll(".CountryFilter");

    const productFilters = getProductFilter();
    const countryFilters = getCountryFilter();
    const applicationFilters = getApplicationFilter();

    let ajaxURL: any;
    let filters: any;

    if (countryCheckboxes.length === 0 && applicationCheckboxes.length > 0) {
        // Only application filters are selected
        ajaxURL = AJAX_LIST.SMEDIAPIM_AJAX_USAGEINSPIRATIONLOADMORE;
        filters = applicationFilters;
    } else if (applicationCheckboxes.length === 0 && countryCheckboxes.length > 0) {
        // Only country filters are selected
        ajaxURL = AJAX_LIST.SMEDIAPIM_AJAX_CUSTOMERREPORTLOADMORE;
        filters = countryFilters;
    } else {
        console.error("Filter Checkbox unavailable.");
        return;
    }

    loadMoreButton.onclick = (e) => {
        e.preventDefault();

        pagination(ajaxURL, e, productFilters, filters);
    };
})();
