// import Swiper from "swiper";
// import { Navigation, Thumbs } from "swiper/modules";

// import "swiper/css";
// import "swiper/css/navigation";

// var thumbSwiper = new Swiper(".productswiper__thumbs", {
//     spaceBetween: 8,
//     observer: true,
//     observeParents: true,
//     watchSlidesProgress: true,
//     // centeredSlides: true,
//     slideToClickedSlide: true,
//     loop: true,
//     freeMode: true,
//     breakpoints: {
//         1199: {
//             slidesPerView: 7,
//         },
//         767: {
//             slidesPerView: 3,
//         },
//         600: {
//             slidesPerView: 2,
//         },
//     },
// });

// var gallerySwiper = new Swiper(".productswiper", {
//     modules: [Navigation, Thumbs],
//     spaceBetween: 10,
//     observer: true,
//     observeParents: true,
//     loop: true,
//     navigation: {
//         nextEl: ".product__button.product__button-next",
//         prevEl: ".product__button.product__button-prev",
//     },
//     thumbs: {
//         swiper: thumbSwiper,
//     },
// });

// gallerySwiper.on("slideChange", () => {
//     thumbSwiper.slideTo(gallerySwiper.activeIndex);
// });

// thumbSwiper.on("slideChange", () => {
//     gallerySwiper.slideTo(thumbSwiper.activeIndex);
// });

// thumbSwiper.on("click", () => {
//     gallerySwiper.slideTo(thumbSwiper.clickedIndex);
//     thumbSwiper.slideTo(thumbSwiper.clickedIndex);
// });



import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

let isSyncing = false;

var thumbSwiper = new Swiper(".productswiper__thumbs", {
    spaceBetween: 7,
    observer: true,
    observeParents: true,
    resizeObserver: true,
    watchSlidesProgress: true,
    slideToClickedSlide: true,
    freeMode: true,
    breakpoints: {
        1765: {
            slidesPerView: 6,
            spaceBetween: 10,
        },
        1475: {
            slidesPerView: 5,
            spaceBetween: 8,
        },
        1180: {
            slidesPerView: 4,
            spaceBetween: 6,
        },
        600: {
            slidesPerView: 3,
            spaceBetween: 5,
        },
        450: {
            slidesPerView: 2,
            spaceBetween: 5,
        },
        320: {
            slidesPerView: 2,
            spaceBetween: 3,
        },
    },
});

window.addEventListener('resize', () => {
    thumbSwiper.update(); // обновляем Swiper при изменении размера окна
});

var gallerySwiper = new Swiper(".productswiper", {
    modules: [Navigation, Thumbs],
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    navigation: {
        nextEl: ".product__button.product__button-next",
        prevEl: ".product__button.product__button-prev",
    },
    thumbs: {
        swiper: thumbSwiper,
    },
});

// Синхронизация слайдов при изменении слайда в gallerySwiper
gallerySwiper.on("slideChange", () => {
    if (!isSyncing) {
        isSyncing = true;
        let slideIndex = gallerySwiper.activeIndex;

        thumbSwiper.slideTo(slideIndex);

        setTimeout(() => {
            isSyncing = false;
        }, 500); // Увеличиваем задержку для стабильности
    }
});

// Синхронизация слайдов при изменении слайда в thumbSwiper
thumbSwiper.on("slideChange", () => {
    if (!isSyncing) {
        isSyncing = true;
        let slideIndex = thumbSwiper.activeIndex;

        gallerySwiper.slideTo(slideIndex);

        setTimeout(() => {
            isSyncing = false;
        }, 500); // Увеличиваем задержку для стабильности
    }
});
