export const countryMap: {
    [key: string]: {
        lat: number;
        lng: number;
        zoom: number;
    };
} = {
    Albania: { lat: 41.1533, lng: 20.1683, zoom: 7 },
    Australia: { lat: -25.2744, lng: 133.7751, zoom: 4 },
    "Bosnia-Herzegovina": { lat: 43.9159, lng: 17.6791, zoom: 8 },
    Brazil: { lat: -14.235, lng: -51.9253, zoom: 4 },
    Bulgaria: { lat: 42.7339, lng: 25.4858, zoom: 7 },
    Canada: { lat: 56.1304, lng: -106.3468, zoom: 3 },
    Chile: { lat: -35.6751, lng: -71.543, zoom: 5 },
    China: { lat: 35.8617, lng: 104.1954, zoom: 4 },
    Croatia: { lat: 45.1, lng: 15.2, zoom: 7 },
    Cyprus: { lat: 35.1264, lng: 33.4299, zoom: 10 },
    "Czech Republic": { lat: 49.8175, lng: 15.473, zoom: 7 },
    Denmark: { lat: 56.2639, lng: 9.5018, zoom: 6 },
    Ecuador: { lat: -1.8312, lng: -78.1834, zoom: 7 },
    Estonia: { lat: 58.5953, lng: 25.0136, zoom: 7 },
    Finland: { lat: 61.9241, lng: 25.7482, zoom: 5 },
    Greece: { lat: 39.0742, lng: 21.8243, zoom: 6 },
    Hungary: { lat: 47.1625, lng: 19.5033, zoom: 7 },
    Iceland: { lat: 64.9631, lng: -19.0208, zoom: 5 },
    India: { lat: 20.5937, lng: 78.9629, zoom: 5 },
    Indonesia: { lat: -0.7893, lng: 113.9213, zoom: 5 },
    Ireland: { lat: 53.1424, lng: -7.6921, zoom: 6 },
    Israel: { lat: 31.0461, lng: 34.8516, zoom: 8 },
    Japan: { lat: 36.2048, lng: 138.2529, zoom: 5 },
    Latvia: { lat: 56.8796, lng: 24.6032, zoom: 7 },
    Lebanon: { lat: 33.8547, lng: 35.8623, zoom: 8 },
    Lithuania: { lat: 55.1694, lng: 23.8813, zoom: 7 },
    Malaysia: { lat: 4.2105, lng: 101.9758, zoom: 6 },
    Malta: { lat: 35.9375, lng: 14.3754, zoom: 10 },
    Mexico: { lat: 23.6345, lng: -102.5528, zoom: 5 },
    Mongolia: { lat: 46.8625, lng: 103.8467, zoom: 5 },
    Morocco: { lat: 31.7917, lng: -7.0926, zoom: 6 },
    "New Zealand": { lat: -40.9006, lng: 174.886, zoom: 5 },
    Norway: { lat: 60.472, lng: 8.4689, zoom: 5 },
    Poland: { lat: 51.9194, lng: 19.1451, zoom: 6 },
    Portugal: { lat: 39.3999, lng: -8.2245, zoom: 7 },
    Romania: { lat: 45.9432, lng: 24.9668, zoom: 6 },
    "Russian Federation": { lat: 61.524, lng: 105.3188, zoom: 3 },
    Serbia: { lat: 44.0165, lng: 21.0059, zoom: 7 },
    "Slovak Republic": { lat: 48.669, lng: 19.699, zoom: 7 },
    Slovenia: { lat: 46.1512, lng: 14.9955, zoom: 8 },
    "South Africa": { lat: -30.5595, lng: 22.9375, zoom: 5 },
    "South Korea": { lat: 35.9078, lng: 127.7669, zoom: 6 },
    Spain: { lat: 40.4637, lng: -3.7492, zoom: 6 },
    Sweden: { lat: 60.1282, lng: 18.6435, zoom: 5 },
    Taiwan: { lat: 23.6978, lng: 120.9605, zoom: 7 },
    Turkey: { lat: 38.9637, lng: 35.2433, zoom: 6 },
    Ukraine: { lat: 48.3794, lng: 31.1656, zoom: 6 },
    "United Arab Emirates": { lat: 23.4241, lng: 53.8478, zoom: 7 },
    "United Kingdom (UK)": { lat: 55.3781, lng: -3.436, zoom: 6 },
    "United States": { lat: 37.0902, lng: -95.7129, zoom: 4 },
    Vietnam: { lat: 14.0583, lng: 108.2772, zoom: 6 },
};
